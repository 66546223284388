export const calculaTamanho = (tamanho, borda) => {
  if (borda) { return `calc(100% * (${tamanho}/12) - ${borda * 2}px)`; }

  if (tamanho === 10) return '83.334%';

  return `calc(100% * (${tamanho}/12))`;
};

export const calculaTamanhoPorcentagem = (porcentagem, borda) => {
  if (borda) { return `calc(${porcentagem} - ${borda * 2}px)`; }
  return porcentagem;
};

export const calculoTotalColeira = () => 1.00;
// switch (count) {
//   case 1:
//     return 49.99;
//   case 2:
//     return 70.00;
//   default:
//     return count * 30.00;
// }

export const getFretevalue = (form, retirarLoja) => {
  let value = 0;

  // verifica se não tem opção de retirar na loja e se tem cep
  if (!retirarLoja && form?.cep?.length > 0 && form?.cepObj?.price) { value = form?.cepObj?.price; }

  // remover apos testes
  value = 0;

  return value;
};

// delivery_time é a soma do production_time com o tempo de envio (por enquanto Sedex)
export const getFretePrazo = (form) => form?.cepObj?.delivery_time;

export const getProducaoPrazo = (form) => form?.empresaObj?.production_time;

export const getTextoPrazo = (form, retirarLoja) => {
  const fretePrazo = getFretePrazo(form);
  const producaoPrazo = getProducaoPrazo(form);
  if (retirarLoja && form?.lojaSelecionada?.length > 0 && producaoPrazo) { return `(${10 + producaoPrazo} dias)`; }
  if (!retirarLoja && form?.cep?.length > 0 && form?.cepObj?.price && fretePrazo) { return `(Sedex - ${fretePrazo} dias)`; }
  return '';
};

export const calculoTotal = (
  form,
  retirarLoja,
  count,
) => calculoTotalColeira(count) + getFretevalue(form, retirarLoja);

export const formularioInicialCompleto = (form) => (form?.nome?.length > 0)
  && (form?.email?.length > 0)
  && (form?.celular?.length > 13)
  && (form?.cpf?.length > 13);

export const formularioCompleto = (form, retirarLoja) => formularioInicialCompleto(form)
  && (form?.cep?.length > 8)
  && (!retirarLoja || (retirarLoja && form?.lojaSelecionada?.length > 0))
  && (form?.rua?.length > 0)
  && (form?.numero?.length > 0)
  && (form?.bairro?.length > 0)
  && (form?.cidade?.length > 0)
  && (form?.estado?.length > 0);
