/* eslint-disable no-console */
import toast from 'react-hot-toast';
import {
  apiUrl,
  apiFetch,
} from './apiHelper';

// old browsers
import 'whatwg-fetch';

export const buscarCep = async (cep, setForm, setBotaoComprarDisable) => {
  if (cep?.length > 7) {
    setBotaoComprarDisable(true);
    toast('Calculando prazo...');
    const url = `${apiUrl()}/vitrine/shipping?cep=${cep}&shop_id=''`;

    const successFunction = (cepObj) => {
      console.log('cep', cepObj);
      setBotaoComprarDisable(false);
      setForm((form) => ({
        ...form,
        cepObj,
        rua: cepObj?.address?.logradouro,
        complemento: cepObj?.address?.complemento,
        bairro: cepObj?.address?.bairro,
        cidade: cepObj?.address?.localidade,
        estado: cepObj?.address?.uf,
      }));
    };

    await apiFetch(url, null, 1, successFunction);
  }
};

const buscarCepEmpresaCache = (lojaSelecionada, successFunction) => {
  let encontrouCep = false;
  const cepLojaSelecionada = localStorage.getItem(lojaSelecionada);
  if (cepLojaSelecionada) {
    try {
      const empresaObj = JSON.parse(cepLojaSelecionada);
      successFunction(empresaObj);
      encontrouCep = true;
    } catch {
      encontrouCep = false;
    }
  }
  return encontrouCep;
};

export const buscarCepEmpresa = async (
  lojaSelecionada,
  setBotaoComprarDisable,
  successFunction,
) => {
  console.log('buscarCepEmpresa');

  if (buscarCepEmpresaCache(lojaSelecionada, successFunction)) { return; }

  const url = `${apiUrl()}/vitrine/shipping?shop_id=${lojaSelecionada}`;
  setBotaoComprarDisable(true);
  toast('Calculando prazo...');

  const successFunctionApi = (empresaObj) => {
    console.log('empresa', empresaObj);
    successFunction(empresaObj);
    localStorage.setItem(lojaSelecionada, JSON.stringify(empresaObj));
  };

  await apiFetch(url, null, 1, successFunctionApi);
};
