import { React } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    font-family: 'Titillium Web', sans-serif;
    text-align: center;
    z-index: 999998;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Text = styled.div`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    ${(props) => (props.isHorizontal ? 'font-size: 28px;' : 'font-size: 22px;')}
    color: #fff;
    text-align: center;
    ${(props) => (props.isHorizontal ? 'margin: 50px 0 70px;' : 'margin: 70px 0 55px;')}
`;

const Popup = styled.div`
    left: 0;
    position: relative;
    ${(props) => (props.isHorizontal ? 'width: 390px;' : 'width: 309px;')}
    height: 100px;
    text-align: left;
    opacity: 1;
    margin-bottom: 50px;
    z-index: 1000;
    background-color: rgb(255, 152, 7);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default function Carregando({ isHorizontal, textoCarregando }) {
  return (
    <Container>
      <Text isHorizontal={isHorizontal}>{textoCarregando}</Text>
      <Popup isHorizontal={isHorizontal}>
        <img width={isHorizontal ? '60px' : '40px'} alt="loading" src={require('../assets/loading/loading-pagarme.gif')} />
      </Popup>
    </Container>
  );
}
