import { React, useContext } from 'react';
import './compraColeira.scss';
import './compraColeiraHorizontal.scss';
import Button from 'components/Botao';
import Slider from 'components/slider';
import ColeiraContext from 'contexts/ColeiraContext';
import {
  calculaTamanho,
  calculoTotalColeira,
} from 'utils/calculosGerais';
import Box from './box';

export const titleText = 'Coleira de Identificação Personalizada Bordada';
const linhaText = 'Ideal para cães e gatos, confeccionada em Fita Polipropileno (Seda), proporciona ao seu Pet elegância, conforto e segurança. Comprimento: 20cm a 56cm, Medida do Pescoço do Pet: 18cm a 54cm e Largura: 1,5cm.';
export const botaoComprarLabel = 'COMPRAR';
export const botaoVoltarLabel = 'VOLTAR';

export default function CompraColeira() {
  const { isHorizontal, setTelaAtual, count } = useContext(ColeiraContext);

  const totalText = `Total: R$ ${calculoTotalColeira(count).toFixed(2).replace('.', ',')}`;
  const img = <img alt="printastico" src={require('assets/logoPrintastico@3x.png')} className="logoImg" />;
  const text = <div className="text" style={{ width: isHorizontal ? '50%' : calculaTamanho(10) }}>{linhaText}</div>;
  const slider = isHorizontal ? <Slider width="90%" /> : <Slider width="58.334%" />;
  const total = <div className="total" style={{ width: '50%' }}>{totalText}</div>;

  const botaoComprar = (
    <Button
      label={botaoComprarLabel}
      width={isHorizontal ? calculaTamanho(3.652) : '50%'}
      height={isHorizontal ? '100%' : '75%'}
      clickHandler={() => { setTelaAtual('formulario'); }}
      className="button"
    />
  );

  const botaoVoltar = (
    <Button
      label={botaoVoltarLabel}
      width={isHorizontal ? calculaTamanho(3.652) : '50%'}
      height={isHorizontal ? '100%' : '75%'}
      clickHandler={() => { setTelaAtual('lp'); }}
      className="button"
      voltar
    />
  );

  if (isHorizontal) {
    return (
      <div className="compra-coleira-horizontal">
        <figure className="logo" role="banner">{img}</figure>
        <header className="title" role="banner">{titleText}</header>
        <section className="linha1" role="complementary" style={{ width: calculaTamanho(10) }}>
          {slider}
          {text}
        </section>
        <main className="linha2" role="main" style={{ width: calculaTamanho(10) }}>
          {total}
          <Box />
        </main>
        <section className="button" role="main" style={{ width: calculaTamanho(11.5) }}>
          {botaoVoltar}
          {botaoComprar}
        </section>
      </div>
    );
  }

  return (
    <div className="compra-coleira">
      <figure className="logo" role="banner">{img}</figure>
      <header className="title">{titleText}</header>
      <section className="linha1" role="complementary">{slider}</section>
      <section className="linha2" role="complementary">{text}</section>
      <main className="linha3">
        {total}
        <Box />
      </main>
      <section className="button-comprar" role="main">{botaoComprar}</section>
      <section className="button-voltar" role="main">{botaoVoltar}</section>
    </div>
  );
}
