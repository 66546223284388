import { React, useContext } from 'react';
import './pedidoFinalizado.scss';
import './pedidoFinalizadoHorizontal.scss';
import { calculaTamanho } from 'utils/calculosGerais';
import Button from 'components/Botao';
import Carregando from 'components/carregando';
import ColeiraContext from 'contexts/ColeiraContext';
import classNames from 'classnames/bind';

export const titleText = 'Seu pedido foi realizado com sucesso!';
const logoImg = <img alt="printastico" className="logoImg" src={require('assets/logoPrintastico@3x.png')} />;
const logoImgHorizontal = <img alt="printastico" src={require('assets/logoPrintastico@3x.png')} className="logoImg" style={{ width: calculaTamanho(3.5) }} />;

const paragraph1 = 'Verifique seu e-mail informado no cadastro, a confirmação de pagamento e as informações para criação de sua Coleira serão enviadas para ele. Não deixe de olhar sua caixa de spam, caso ainda não tenha recebido nosso e-mail.';
const paragraph2 = (isHorizontal) => (
  <>
    Qualquer dúvida ou não recebimento do e-mail, nos envie uma mensagem para
    {'\n'}
    <strong>suporte@printastico.com.br.</strong>
    {'\n'}
    Caso prefira, entre em contato através das nossas redes sociais:
    <strong>
      {' '}
      Facebook
      {isHorizontal && '\n'}
      {' '}
      @printastico.br
    </strong>
    {' '}
    e
    <strong>Instagram @printastico.</strong>
  </>
);

export default function PedidoFinalizado() {
  const { isHorizontal, boletoUrl, textoCarregando } = useContext(ColeiraContext);
  const pedidoFinalizadoClassName = classNames({
    'pedido-finalizado': !isHorizontal,
    'pedido-finalizado-horizontal': isHorizontal,
  });

  return (
    <div className={pedidoFinalizadoClassName}>
      <div className="container">
        {textoCarregando?.length > 0
                    && <Carregando isHorizontal={isHorizontal} textoCarregando={textoCarregando} />}

        {isHorizontal && <div className="header" />}

        <div className="logo">{isHorizontal ? logoImgHorizontal : logoImg}</div>

        <div className="title">
          <div style={{ width: calculaTamanho(8) }}>{titleText}</div>
        </div>

        <div className="paragraph-1">
          <div className="paragraph" style={{ width: calculaTamanho(10) }}>{paragraph1}</div>
        </div>

        <div className="paragraph-2">
          <div className="paragraph" style={{ width: calculaTamanho(10), whiteSpace: 'pre-line' }}>{paragraph2(isHorizontal)}</div>
        </div>

        <div className="button">
          {boletoUrl?.length > 0 ? (
            <Button
              label="SALVAR BOLETO"
              width={isHorizontal ? calculaTamanho(3.5) : '50%'}
              height={isHorizontal ? '100%' : '75%'}
              style={{ alignSelf: `${isHorizontal ? 'flex-start' : 'flex-end'}` }}
              clickHandler={() => window.open(`${boletoUrl}?format=pdf`, '_blank')}
              className="button"
            />
          ) : null}
        </div>

        <div className="footer" />
      </div>
    </div>
  );
}
