/* eslint-disable no-console */
import {
  apiUrl,
  apiFetch,
} from './apiHelper';

import { buscarCepEmpresa } from './buscarCep';

// old browsers
import 'whatwg-fetch';

export const getLojaCombo = (shop) => {
  const name = shop?.nome;
  const streetName = shop?.streetName ? ` - ${shop?.streetName}` : '';
  const streetNumber = shop?.streetNumber ? `, ${shop?.streetNumber}` : '';
  const neighborhood = shop?.neighborhood ? `, ${shop?.neighborhood}` : '';
  const city = shop?.city ? `, ${shop?.city}` : '';
  const state = shop?.state ? `, ${shop?.state}` : '';

  return name + streetName + streetNumber + neighborhood + city + state;
};

export const buscarLojaUnica = async (lojaId, setForm, setBotaoComprarDisable) => {
  const url = `${apiUrl()}/vitrine/shop/${lojaId}`;

  const successFunctionAsync = async ({ shop }) => {
    const successFunction = (empresaObj) => {
      setBotaoComprarDisable(false);
      setForm((prevForm) => ({
        ...prevForm,
        empresaObj,
        shops: [shop],
      }));
    };
    await buscarCepEmpresa(
      lojaId,
      setBotaoComprarDisable,
      successFunction,
    );
  };

  await apiFetch(url, null, 1, successFunctionAsync);
};

export const buscarTodasLojas = async (setForm, setBotaoComprarDisable) => {
  const url = `${apiUrl()}/vitrine/shops`;

  const successFunction = ({ shops }) => {
    setBotaoComprarDisable(false);
    setForm((form) => ({
      ...form,
      shops,
    }));
  };

  await apiFetch(url, null, 1, successFunction);
};

export const buscarLojas = async (
  retirarLoja,
  setRetiraLoja,
  form,
  setForm,
  setBotaoComprarDisable,
) => {
  setRetiraLoja(!retirarLoja);

  if (form?.shops?.length <= 0 && !retirarLoja) {
    setBotaoComprarDisable(true);

    if (form?.shopId?.length > 0) {
      await buscarLojaUnica(form.shopId, setForm, setBotaoComprarDisable);
    } else {
      await buscarTodasLojas(setForm, setBotaoComprarDisable);
    }
  }
};
