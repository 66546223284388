import React from 'react';

function Paragraph({
  subTitle,
  text,
  width,
  alignSelf = 'center',
}) {
  return (
    <div style={{
      alignSelf,
      width,
    }}
    >
      <p
        style={{
          fontFamily: 'LatoBold',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {subTitle}
      </p>
      <p
        className="text"
        style={{
          fontFamily: 'Lato',
          fontSize: '14px',
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default Paragraph;
