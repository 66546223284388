import { React, useState, useContext } from 'react';
import './textInput.scss';
import { mask as masker, unMask } from 'remask';
import { buscarCep } from '../../utils/buscarCep';
import ColeiraContext from '../../contexts/ColeiraContext';

function TextInput({
  value,
  setValue,
  mask,
  placeholderText,
  focusName,
  width,
  height,
  textWidth,
  alignSelf = 'center',
  incomplete = false,
  disabled,
  setForm,
}) {
  const { setBotaoComprarDisable } = useContext(ColeiraContext);
  const [inputFocus, setInputFocus] = useState('');

  const borderColor = () => {
    if (incomplete) { return '#EB0000'; }
    if (inputFocus === focusName) { return '#FF9807'; }
    return '#CCCCCC';
  };

  const boxShadow = () => {
    if (incomplete) { return '0 0 5px #EB0000'; }
    if (inputFocus === focusName) { return '0 0 5px #FF9807'; }
    return '';
  };

  return (
    <div style={{
      display: 'flex',
      width,
      height,
      justifyContent: 'center',
      alignSelf,
      backgroundColor: '#F5F5F5',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: borderColor(),
      boxShadow: boxShadow(),
      opacity: disabled ? 0.25 : 1,
    }}
    >
      <input
        style={{
          width: textWidth,
          height: '75%',
          alignSelf: 'center',
          justifySelf: 'center',
          backgroundColor: '#F5F5F5',
          color: '#2D2D2D',
          fontFamily: 'LatoBold',
          fontWeight: 'bold',
          fontSize: 18,
          outlineWidth: 0,
          border: 'none',
        }}
        value={value}
        onChange={(ev) => {
          let { value: inputValue } = ev.target;
          if (mask) {
            const originalValue = unMask(inputValue);
            inputValue = masker(originalValue, mask);
          }
          setValue(focusName, inputValue, true);
        }}
        onFocus={() => setInputFocus(focusName)}
        onBlur={
          async () => {
            setInputFocus('');
            if (inputFocus === 'cep') { await buscarCep(unMask(value), setForm, setBotaoComprarDisable); } else { setValue(`${focusName}Changed`, true); }
          }
        }
        placeholder={placeholderText}
        disabled={disabled}
      />
    </div>
  );
}

export default TextInput;
