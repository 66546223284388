/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useContext } from 'react';
import { buscarLojas } from 'utils/buscarLojas';
import ColeiraContext from 'contexts/ColeiraContext';
import { calculaTamanho, calculaTamanhoPorcentagem } from 'utils/calculosGerais';

export default function CheckBox() {
  const {
    isHorizontal,
    form,
    setForm,
    retirarLoja,
    setRetirarLoja,
    botaoComprarDisable,
    setBotaoComprarDisable,
  } = useContext(ColeiraContext);

  return (
    <div
      className="checkbox"
      style={{
        display: 'flex',
        width: isHorizontal ? '100%' : calculaTamanhoPorcentagem('45%'),
        height: calculaTamanhoPorcentagem(isHorizontal ? '46%' : '75%'),
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignSelf: 'center',
        paddingLeft: calculaTamanho(isHorizontal ? 0.6 : 0),
        opacity: botaoComprarDisable ? 0.25 : 1,
      }}
    >
      <div className="form-group" style={{ opacity: botaoComprarDisable ? 0.25 : 1 }}>
        <input
          id="retirar"
          type="checkbox"
          checked={retirarLoja}
          onChange={async () => buscarLojas(
            retirarLoja,
            setRetirarLoja,
            form,
            setForm,
            setBotaoComprarDisable,
          )}
          disabled={botaoComprarDisable}
        />
        <label className={retirarLoja ? 'checked' : 'default'} htmlFor="retirar">Retirar na loja</label>
      </div>
    </div>
  );
}
