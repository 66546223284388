/* eslint-disable react/jsx-no-constructed-context-values */
import {
  React, useState, useEffect, useCallback,
} from 'react';
import './Coleira.scss';
import { isMobile } from 'react-device-detect';
import ColeiraContext from 'contexts/ColeiraContext';
import Fundo from 'assets/fundo@3x.jpg';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import PedidoFinalizado from './PedidoFinalizado';
import Formulario from './Formulario';
import FormularioCep from './FormularioCep';
import Lp from './Lp';
import CompraColeira from './CompraColeira';

function Coleira() {
  const getHorizontal = useCallback(() => {
    const orientationDegree = window?.orientation;
    if (typeof orientationDegree !== 'number') {
      const width = window?.innerWidth;
      const height = window?.innerHeight;
      return width > height;
    }
    return orientationDegree === 90 || orientationDegree === -90;
  }, []);

  const [isHorizontal, setIsHorizontal] = useState(getHorizontal());
  const [telaAtual, setTelaAtual] = useState('lp');
  const [boletoUrl, setBoletoUrl] = useState();
  const [textoCarregando, setTextoCarregando] = useState();
  const [count, setCount] = useState(1);
  const { search } = useLocation();
  const shopId = new URLSearchParams(search).get('shop_id');
  const [retirarLoja, setRetirarLoja] = useState(false);
  const [botaoComprarDisable, setBotaoComprarDisable] = useState(false);
  const [form, setForm] = useState({
    nome: '',
    email: '',
    celular: '',
    cpf: '',
    cep: '',
    rua: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    cepObj: null,
    cepInvalid: false,
    shopId: shopId || '',
    shops: [],
    empresaObj: null,
    empresaInvalid: false,
    lojaSelecionada: shopId || '',
    nomeChanged: false,
    nomeInvalid: false,
    emailChanged: false,
    emailInvalid: false,
    celularChanged: false,
    celularInvalid: false,
    cpfChanged: false,
    cpfInvalid: false,
    ruaChanged: false,
    ruaInvalid: false,
    numeroChanged: false,
    numeroInvalid: false,
    bairroChanged: false,
    bairroInvalid: false,
    cidadeChanged: false,
    cidadeInvalid: false,
    estadoChanged: false,
    estadoInvalid: false,
  });

  useEffect(() => {
    function handleResize() {
      setIsHorizontal(getHorizontal());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const selecionaTela = () => {
    switch (telaAtual) {
      case 'compra-coleira': return <CompraColeira />;
      case 'formulario': return <Formulario />;
      case 'formulario-cep': return <FormularioCep />;
      case 'pedidoFinalizado': return <PedidoFinalizado />;
      default: return <Lp />;
    }
  };

  return (
    <div className="Coleira">
      <header
        className={isHorizontal ? 'Coleira-header-horizontal' : 'Coleira-header'}
        style={{
          backgroundImage: `url(${Fundo})`,
          backgroundRrepeat: 'no-repeat',
          backgroundSize: isHorizontal ? '201.5%' : '305%',
          backgroundOrigin: 'border-box',
          backgroundPositionY: isHorizontal ? '37.8%' : '48.4%',
          backgroundPositionX: isHorizontal ? '47%' : '48%',
        }}
      >
        <ColeiraContext.Provider value={{
          isHorizontal,
          isMobile,
          setTelaAtual,
          count,
          setCount,
          form,
          setForm,
          retirarLoja,
          setRetirarLoja,
          botaoComprarDisable,
          setBotaoComprarDisable,
          boletoUrl,
          setBoletoUrl,
          textoCarregando,
          setTextoCarregando,
        }}
        >
          {selecionaTela()}
          <Toaster
            position="top-center"
            reverseOrder
          />
        </ColeiraContext.Provider>
      </header>
    </div>
  );
}

export default Coleira;
