import { React, useContext } from 'react';
import './lp.scss';
import './lpHorizontal.scss';
import Button from 'components/Botao';
import Paragraph from 'components/paragraph';
import ColeiraContext from 'contexts/ColeiraContext';
import { calculaTamanho } from 'utils/calculosGerais';
import classNames from 'classnames/bind';

export const titleText = 'Antes de fazer seu pedido, leia nossas informações';
const paragraph1Text = 'As Coleiras estão disponíveis em 5 cores: Amarelo, Azul, Preto, Rosa e Vermelho. Com fontes 11 disponíveis em 125 cores. O valor unitário é de R$ 49,99.';
const paragraph2Text = 'Após o pagamento, nós te enviaremos um e-mail. Recomendamos olhar a caixa de spam, caso demore para receber. Siga as instruções do e-mail, para criar sua Coleira em seu computador.';
const paragraph3Text = 'A produção é de até 5 dias úteis, após a criação. Recebendo em casa, enviaremos por Correios via Sedex. Retirando na loja, te informaremos quando seu pedido estiver na loja escolhida.';
export const buttonLabel = 'COMPRAR';

export default function Lp() {
  const { isHorizontal, setTelaAtual } = useContext(ColeiraContext);
  const titleWidth = calculaTamanho(10);
  const paragraphWidth = calculaTamanho(isHorizontal ? 11 : 10);
  const paragraphAlignSelf = isHorizontal ? 'flex-start' : 'center';

  const lpClassName = classNames({
    lp: !isHorizontal,
    'lp-horizontal': isHorizontal,
  });

  return (
    <div className={lpClassName}>

      <figure className="logo" role="banner">
        <img alt="logo printastico" src={require('assets/logoPrintastico@3x.png')} className="logoImg" />
      </figure>

      <header className="title" style={{ width: titleWidth }}>
        {titleText}
      </header>

      <section className="paragraph-1" role="main" aria-label={paragraph1Text}>
        <Paragraph
          width={paragraphWidth}
          subTitle="Sobre o produto"
          text={paragraph1Text}
          alignSelf={paragraphAlignSelf}
        />
      </section>

      <section className="paragraph-2" role="main" aria-label={paragraph2Text}>
        <Paragraph
          width={paragraphWidth}
          subTitle="Como criar sua Coleira"
          text={paragraph2Text}
          alignSelf={paragraphAlignSelf}
        />
      </section>

      <section className="paragraph-3" role="main" aria-label={paragraph3Text}>
        <Paragraph
          width={paragraphWidth}
          subTitle="Como funciona o prazo e a entrega"
          text={paragraph3Text}
          alignSelf={paragraphAlignSelf}
        />
      </section>

      <main className="button" aria-label="Encaminhar para a página de compra das coleiras">
        <Button
          label={buttonLabel}
          width={isHorizontal ? calculaTamanho(3.5) : '50%'}
          height={isHorizontal ? '100%' : '75%'}
          clickHandler={() => { setTelaAtual('compra-coleira'); }}
          className="button"
        />
      </main>
    </div>
  );
}
