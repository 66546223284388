import { React, useEffect, useReducer } from 'react';

const TIMEOUT = 2000;

const images = [
  require('../assets/slider/c1@3x.png'),
  require('../assets/slider/c2@3x.png'),
  require('../assets/slider/c3@3x.png'),
  require('../assets/slider/c4@3x.png'),
  require('../assets/slider/c5@3x.png'),
];

const reducer = (image, action) => {
  if (action.type === 'next') {
    const index = images.findIndex((img) => img === image);
    return images[(index + 1) >= images.length ? 0 : index + 1];
  }
  return image;
};

export default function Slider({ width }) {
  const [image, dispatch] = useReducer(reducer, images[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: 'next' });
    }, TIMEOUT);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ flex: 1 }}>
      <img
        src={image}
        alt="coleiras"
        style={{
          objectFit: 'contain',
          alignSelf: 'center',
          width,
          height: '100%',
        }}
      />
    </div>
  );
}
