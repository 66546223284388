import { React, useContext } from 'react';
import './formulario.scss';
import './formularioHorizontal.scss';
import TextInput from 'components/TextInput';
import ColeiraContext from 'contexts/ColeiraContext';
import {
  calculaTamanho,
  calculaTamanhoPorcentagem,
  calculoTotal,
  formularioInicialCompleto,
} from 'utils/calculosGerais';
import * as compForm from './componentsForm';

export const titleText = 'Preencha os campos abaixo para validarmos seu pedido';
export const botaoComprarLabel = 'COMPRAR';
export const botaoVoltarLabel = 'VOLTAR';

export default function Formulario() {
  const {
    isHorizontal,
    count,
    setTelaAtual,
    form,
    setForm,
    retirarLoja,
    botaoComprarDisable,
  } = useContext(ColeiraContext);

  const img = <img alt="printastico" src={require('assets/logoPrintastico@3x.png')} className="logoImg" />;

  const setFormulary = (prop, value, valid = false) => {
    if (valid) { setForm({ ...form, [prop]: value, [`${prop}Invalid`]: false }); } else { setForm({ ...form, [prop]: value }); }
  };

  const nome = (
    <TextInput
      value={form.nome}
      setValue={setFormulary}
      placeholderText="Nome e Sobrenome"
      focusName="nome"
      width={calculaTamanho(isHorizontal ? 5.7 : 10, 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '90.185%' : '90.605%'}
      alignSelf="center"
      incomplete={(form?.nome?.length <= 0 && form.nomeChanged) || form.nomeInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const email = (
    <TextInput
      value={form.email}
      setValue={setFormulary}
      placeholderText="E-mail"
      focusName="email"
      width={calculaTamanho(isHorizontal ? 5.7 : 10, 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '90.185%' : '90.605%'}
      alignSelf="center"
      incomplete={(form?.email?.length <= 0 && form.emailChanged) || form.emailInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const celular = (
    <TextInput
      value={form.celular}
      setValue={setFormulary}
      mask={['(99) 99999-9999', '(99) 9999-9999']}
      placeholderText="Celular"
      focusName="celular"
      width={isHorizontal ? calculaTamanho(5.7, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf="center"
      incomplete={(form?.celular?.length <= 13 && form.celularChanged) || form.celularInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const cpf = (
    <TextInput
      value={form.cpf}
      setValue={setFormulary}
      mask={['999.999.999-99']}
      placeholderText="CPF"
      focusName="cpf"
      width={isHorizontal ? calculaTamanho(5.7, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf="center"
      incomplete={(form?.cpf?.length <= 13 && form.cpfChanged) || form.cpfInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const total = (
    <div className="total">
      Total: R$
      {calculoTotal(form, retirarLoja, count).toFixed(2).replace('.', ',')}
    </div>
  );

  const botaoComprar = compForm.botaoComprar(
    isHorizontal,
    () => setTelaAtual('formulario-cep'),
    botaoComprarDisable || !formularioInicialCompleto(form),
  );

  const botaoVoltar = compForm.botaoVoltar(
    isHorizontal,
    () => setTelaAtual('compra-coleira'),
  );

  if (isHorizontal) {
    return (
      <div className="formulario-horizontal">
        <figure className="logo" role="banner">{img}</figure>
        <header className="title">{titleText}</header>
        <main className="linha-1" role="form" style={{ width: calculaTamanho(10) }}>
          {nome}
          {email}
        </main>
        <section className="linha-2" role="form" style={{ width: calculaTamanho(10) }}>
          {celular}
          {cpf}
        </section>
        <section className="linha-3" role="complementary">
          {total}
        </section>
        <section className="button" role="form" style={{ width: calculaTamanho(11.5) }}>
          {botaoVoltar}
          {botaoComprar}
        </section>
      </div>
    );
  }

  return (
    <div className="formulario">
      <figure className="logo" role="banner">{img}</figure>
      <header className="title">{titleText}</header>
      <main className="linha-1" role="form">{nome}</main>
      <section className="linha-2" role="form">{email}</section>
      <section className="linha-3" role="form">
        {celular}
        {cpf}
      </section>
      <section className="total" width={calculaTamanho(10)} role="complementary">{total}</section>
      <section className="button-comprar" role="form">{botaoComprar}</section>
      <section className="button-voltar" role="form">{botaoVoltar}</section>
    </div>
  );
}
