import { React, useState, useContext } from 'react';
import ColeiraContext from 'contexts/ColeiraContext';
import { calculaTamanhoPorcentagem } from 'utils/calculosGerais';

const MAX_COUNT = 100;
const MIN_COUNT = 1;

export default function Box() {
  const { isHorizontal, count, setCount } = useContext(ColeiraContext);
  const [boxFocus, setBoxFocus] = useState(false);

  return (
    <div style={{
      display: 'flex',
      height: 'fit-content',
      flexDirection: 'row',
      width: '50%',
      alignContent: 'center',
      alignSelf: 'center',
      justifyContent: 'space-between',
    }}
    >
      <input
        type="button"
        className={count === MIN_COUNT ? 'setaBaixoDesativado' : 'setaBaixo'}
        onClick={() => setCount(count > MIN_COUNT ? count - 1 : MIN_COUNT)}
      />

      <div
        className="box"
        onFocus={() => setBoxFocus(true)}
        style={{
          width: isHorizontal ? calculaTamanhoPorcentagem('40%', 1) : '88px',
          height: isHorizontal ? '35.98px' : '38px',
          borderColor: boxFocus ? '#FF9807' : '#CCCCCC',
          boxShadow: boxFocus ? '0 0 5px #FF9807' : '',
        }}
      >
        <input
          type="number"
          className="text-input-box"
          onChange={({ target: { value } }) => {
            const textNumber = Number.parseInt(value, 10);
            setCount(!Number.isNaN(textNumber) ? textNumber : count);
          }}
          onBlur={() => {
            setBoxFocus(false);
            if (count > MAX_COUNT) { setCount(MAX_COUNT); }
            if (count < MIN_COUNT) { setCount(MIN_COUNT); }
          }}
          value={String(count).padStart(2, '0')}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <input
          type="button"
          className={count === MAX_COUNT ? 'setaCimaDesativado' : 'setaCima'}
          onClick={() => setCount(count < MAX_COUNT ? count + 1 : MAX_COUNT)}
        />
      </div>
    </div>
  );
}
