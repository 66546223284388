import { React, useContext } from 'react';
import ColeiraContext from 'contexts/ColeiraContext';
import './formularioCep.scss';
import './formularioCepHorizontal.scss';
import Button from 'components/Botao';
import TextInput from 'components/TextInput';
import {
  calculaTamanho,
  calculaTamanhoPorcentagem,
  formularioCompleto,
  calculoTotalColeira,
  calculoTotal,
  getFretevalue,
  getTextoPrazo,
} from 'utils/calculosGerais';
import { envioPagarme } from 'utils/envioPagarme';
import SelectBox from './selectBox';
import CheckBox from './checkBox';
import * as CompForm from '../Formulario/componentsForm';

export const titleText = 'Preencha os campos abaixo para validarmos seu pedido';
export const botaoComprarLabel = 'COMPRAR';

export default function FormularioCep() {
  const {
    isHorizontal,
    form,
    setForm,
    count,
    retirarLoja,
    botaoComprarDisable,
    setTelaAtual,
    setBoletoUrl,
    setTextoCarregando,
    setBotaoComprarDisable,
  } = useContext(ColeiraContext);

  const img = <img alt="printastico" src={require('assets/logoPrintastico@3x.png')} className="logoImg" />;

  const setFormulary = (prop, value, valid = false) => {
    if (valid) {
      setForm({ ...form, [prop]: value, [`${prop}Invalid`]: false });
    } else {
      setForm({ ...form, [prop]: value });
    }
  };

  const cep = (
    <TextInput
      value={form.cep}
      setValue={setFormulary}
      mask={['99999-999']}
      placeholderText="CEP"
      focusName="cep"
      width={isHorizontal ? calculaTamanho(5.515, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf="center"
      incomplete={(form?.cep?.length <= 8 && form?.cepObj) || form?.cepInvalid}
      disabled={botaoComprarDisable}
      setForm={setForm}
    />
  );

  const rua = (
    <TextInput
      value={form.rua}
      setValue={setFormulary}
      placeholderText="Rua"
      focusName="rua"
      width={calculaTamanho(isHorizontal ? 3.6 : 10, 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '90.605%'}
      alignSelf={isHorizontal ? 'flex-start' : 'center'}
      incomplete={(form?.rua?.length <= 0 && form.ruaChanged) || form.ruaInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const numero = (
    <TextInput
      value={form.numero}
      setValue={setFormulary}
      placeholderText="Número"
      focusName="numero"
      width={isHorizontal ? calculaTamanho(3.6, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf={isHorizontal ? 'flex-start' : 'center'}
      incomplete={(form?.numero?.length <= 0 && form.numeroChanged) || form.numeroInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const complemento = (
    <TextInput
      value={form.complemento}
      setValue={setFormulary}
      placeholderText="Compl."
      focusName="complemento"
      width={isHorizontal ? calculaTamanho(3.6, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '63.3%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf={isHorizontal ? 'flex-start' : 'center'}
      disabled={botaoComprarDisable}
    />
  );

  const bairro = (
    <TextInput
      value={form.bairro}
      setValue={setFormulary}
      placeholderText="Bairro"
      focusName="bairro"
      width={calculaTamanho(isHorizontal ? 3.6 : 10, 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '126.6%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '90.605%'}
      alignSelf={isHorizontal ? 'flex-end' : 'center'}
      incomplete={(form?.bairro?.length <= 0
        && form.bairroChanged)
        || form.bairroInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const cidade = (
    <TextInput
      value={form.cidade}
      setValue={setFormulary}
      placeholderText="Cidade"
      focusName="cidade"
      width={isHorizontal ? calculaTamanho(3.6, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '126.6%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf={isHorizontal ? 'flex-end' : 'center'}
      incomplete={(form?.cidade?.length <= 0 && form.cidadeChanged) || form.cidadeInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const estado = (
    <TextInput
      value={form.estado}
      setValue={setFormulary}
      placeholderText="Estado"
      focusName="estado"
      width={isHorizontal ? calculaTamanho(3.6, 1) : calculaTamanhoPorcentagem('45%', 1)}
      height={calculaTamanhoPorcentagem(isHorizontal ? '126.6%' : '75%', 1)}
      textWidth={isHorizontal ? '84.37%' : '78.95%'}
      alignSelf={isHorizontal ? 'flex-end' : 'center'}
      incomplete={(form?.estado?.length <= 0 && form.estadoChanged) || form.estadoInvalid}
      disabled={botaoComprarDisable}
    />
  );

  const total = (
    <div className="total">
      <div className="subtotal">
        R$
        {calculoTotalColeira(count).toFixed(2).replace('.', ',')}
        {' '}
        + R$
        {getFretevalue(form, retirarLoja).toFixed(2).replace('.', ',')}
        {' '}
        {getTextoPrazo(form, retirarLoja)}
      </div>
      <div className="total-final">
        Total: R$
        {calculoTotal(form, retirarLoja, count).toFixed(2).replace('.', ',')}
      </div>
    </div>
  );

  const botaoComprar = (
    <Button
      label={botaoComprarLabel}
      width={isHorizontal ? calculaTamanho(3.652) : '50%'}
      height={isHorizontal ? '100%' : '75%'}
      clickHandler={
            async () => envioPagarme(
              form,
              retirarLoja,
              count,
              setFormulary,
              setTelaAtual,
              setBoletoUrl,
              setTextoCarregando,
              botaoComprarDisable,
              setBotaoComprarDisable,
            )
        }
      disabled={botaoComprarDisable || !formularioCompleto(form, retirarLoja)}
      className="button"
    />
  );

  const botaoVoltar = CompForm.botaoVoltar(
    isHorizontal,
    () => setTelaAtual('formulario'),
    botaoComprarDisable,
  );

  if (isHorizontal) {
    return (
      <div className="formulario-cep-horizontal">
        <header className="logo" role="banner">{img}</header>
        <main className="linha-1" role="form" style={{ width: calculaTamanho(10) }}>
          {cep}
          <CheckBox />
        </main>
        <section className="linha-2" role="form" style={{ width: calculaTamanho(10) }}>
          {rua}
          {numero}
          {complemento}
        </section>
        <section className="linha-3" role="form" style={{ width: calculaTamanho(10) }}>
          {bairro}
          {cidade}
          {estado}
        </section>
        <section className="linha-4" role="complementary" style={{ width: calculaTamanho(10) }}>
          <SelectBox />
          {total}
        </section>
        <section className="button" role="form" style={{ width: calculaTamanho(11.5) }}>
          {botaoVoltar}
          {botaoComprar}
        </section>
      </div>
    );
  }

  return (
    <div className="formulario-cep">
      <header className="logo" role="banner">{img}</header>
      <main className="linha-1" role="form">
        {cep}
        <CheckBox />
      </main>
      <section className="linha-2" role="form">{rua}</section>
      <section className="linha-3" role="form">
        {numero}
        {complemento}
      </section>
      <section className="linha-4" role="form">{bairro}</section>
      <section className="linha-5" role="form">
        {cidade}
        {estado}
      </section>
      <section className="linha-6" role="form">
        <SelectBox />
      </section>
      <section className="total" role="complementary" width={calculaTamanho(10)}>
        {total}
      </section>
      <section className="button-comprar" role="form">{botaoComprar}</section>
      <section className="button-voltar" role="form">{botaoVoltar}</section>
    </div>
  );
}
