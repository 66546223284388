import { React, useState, useContext } from 'react';
import { getLojaCombo } from 'utils/buscarLojas';
import { buscarCepEmpresa } from 'utils/buscarCep';
import ColeiraContext from 'contexts/ColeiraContext';
import {
  calculaTamanho,
  calculaTamanhoPorcentagem,
} from 'utils/calculosGerais';

export default function SelectBox() {
  const {
    isHorizontal, form, setForm, retirarLoja, botaoComprarDisable, setBotaoComprarDisable,
  } = useContext(ColeiraContext);
  const [selectBoxClass, setSelectBoxClass] = useState('selectBox');

  return (
    <select
      className={selectBoxClass}
      value={form.lojaSelecionada}
      disabled={botaoComprarDisable}
      style={{
        width: isHorizontal ? calculaTamanho(8.375, 1) : calculaTamanho(10),
        height: isHorizontal ? calculaTamanhoPorcentagem('66.64%', 1) : '75%',
        alignSelf: 'center',
        visibility: retirarLoja ? 'visible' : 'hidden',
        fontSize: '18px',
        color: '#2D2D2D',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: retirarLoja && form?.lojaSelecionada?.length <= 0 && !form.empresaObj ? '#EB0000' : '#CCCCCC',
        paddingLeft: isHorizontal ? '2.3%' : calculaTamanho(0.5),
        paddingRight: calculaTamanho(isHorizontal ? 0.9 : 1.5),
        opacity: botaoComprarDisable ? 0.25 : 1,
      }}
      onChange={
                  async ({ target: { value: lojaSelecionada } }) => {
                    if (lojaSelecionada?.length > 0 && lojaSelecionada !== form.lojaSelecionada) {
                      const successFunction = (empresaObj) => {
                        setBotaoComprarDisable(false);
                        setForm((prevForm) => ({
                          ...prevForm,
                          empresaObj,
                          lojaSelecionada,
                        }));
                      };
                      await buscarCepEmpresa(
                        lojaSelecionada,
                        setBotaoComprarDisable,
                        successFunction,
                      );
                    }
                  }
                }
      onMouseEnter={() => setSelectBoxClass('selectBoxHover')}
      onMouseLeave={() => { if (selectBoxClass === 'selectBoxHover') setSelectBoxClass('selectBox'); }}
      onMouseDown={() => setSelectBoxClass('selectBoxActive')}
      onBlur={() => setSelectBoxClass('selectBox')}
    >
      <option value="" hidden>Selecione a loja</option>
      { form?.shops?.map((shop) => <option id={shop?.id} key={shop?.id} value={shop?.id} data-limit="10" style={{ width: '250px' }}>{getLojaCombo(shop)}</option>)}
    </select>
  );
}
