import React from 'react';
import './button.scss';

function Button({
  label,
  dataTip,
  clickHandler,
  width,
  height,
  style,
  voltar,
  disabled,
}) {
  let disabledClassName = disabled ? 'defaultConfigDisabled' : 'defaultConfig';
  if (voltar) disabledClassName = disabled ? 'voltarConfigDisabled' : 'voltarConfig';

  return (
    <button
      type="button"
      className={disabledClassName}
      onClick={clickHandler}
      style={{
        ...style,
        width,
        height,
      }}
      data-tip={dataTip}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export default Button;
