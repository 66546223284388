/* eslint-disable no-console */
import { calculoTotal } from './calculosGerais';

export const apiUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      if (process.env.REACT_APP_DEPLOY_STAGE === 'staging') { return process.env.REACT_APP_STAGING_API_URL; }
      return process.env.REACT_APP_PRODUCTION_API_URL;
    default:
      return process.env.REACT_APP_DEVELOPMENT_API_URL;
  }
};

export const verificaSucesso = (result) => result?.errorcode === 0 || result?.errorcode === '0';

export const apiFetch = async (url, requestOptions, count, successFunction) => {
  let success = false;
  try {
    const result = await (await fetch(url, requestOptions)).json();
    success = verificaSucesso(result);

    if (success) {
      successFunction(result);
    }
  } catch (err) {
    console.log('Erro ao buscar dados da api ', err);
    success = false;
  } finally {
    if (!success) {
      setTimeout(async () => apiFetch(
        url,
        requestOptions,
        count + 1,
        successFunction,
      ), 1000 * count);
    }
  }
};

export const getLojaId = (form) => (form?.lojaSelecionada?.length > 0 ? form?.lojaSelecionada : 7);

export const formularioCompleto = (form, retirarLoja, setFormulary) => {
  if (!form?.email?.includes('@') || !form?.email?.includes('.')) {
    setFormulary('emailInvalid', true);
    return [false, 'Favor preencher um e-mail válido'];
  }

  if (retirarLoja && form?.lojaSelecionada?.length <= 0) return [false, 'Favor selecionar uma loja'];
  if (form?.cep?.length > 0 && !form?.cepObj) return [false, 'Sem dados de cep'];
  if (retirarLoja && form?.lojaSelecionada?.length > 0 && !form?.empresaObj) return [false, 'Sem dados da empresa'];
  return [true, 'Formulário completo'];
};

export const calculoAmountColeira = (form, retirarLoja, count) => {
  const total = calculoTotal(form, retirarLoja, count).toFixed(2).replace('.', '');
  return Number.parseInt(total, 10);
};

export const trataErro = (errorfield, setFormulary) => {
  if (errorfield === 'name') {
    setFormulary('nomeInvalid', true);
  } else if (errorfield === 'email') {
    setFormulary('emailInvalid', true);
  } else if (errorfield === 'cpf') {
    setFormulary('cpfInvalid', true);
  } else if (errorfield === 'phone') {
    setFormulary('celularInvalid', true);
  } else if (errorfield === 'shop_id') {
    setFormulary('empresaObj', null);
  } else if (errorfield === 'cep') {
    setFormulary('cepInvalid', true);
  } else if (errorfield === 'logradouro') {
    setFormulary('ruaInvalid', true);
  } else if (errorfield === 'numero') {
    setFormulary('numeroInvalid', true);
  } else if (errorfield === 'bairro') {
    setFormulary('bairroInvalid', true);
  } else if (errorfield === 'localidade') {
    setFormulary('cidadeInvalid', true);
  } else if (errorfield === 'uf') {
    setFormulary('estadoInvalid', true);
  }
};

export const resetaErros = (form, setFormulary, botaoComprarDisable, setBotaoComprarDisable) => {
  if (form.nomeInvalid) setFormulary('nomeInvalid', false);
  if (form.emailInvalid) setFormulary('emailInvalid', false);
  if (form.cpfInvalid) setFormulary('cpfInvalid', false);
  if (form.celularInvalid) setFormulary('celularInvalid', false);
  if (form.cepInvalid) setFormulary('cepInvalid', false);
  if (form.ruaInvalid) setFormulary('ruaInvalid', false);
  if (form.numeroInvalid) setFormulary('numeroInvalid', false);
  if (form.bairroInvalid) setFormulary('bairroInvalid', false);
  if (form.cidadeInvalid) setFormulary('cidadeInvalid', false);
  if (form.estadoInvalid) setFormulary('estadoInvalid', false);
  if (botaoComprarDisable) setBotaoComprarDisable(false);
};
