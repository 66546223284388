import { React } from 'react';
import Button from 'components/Botao';

import { calculaTamanho } from 'utils/calculosGerais';

export const botaoComprarLabel = 'COMPRAR';
export const botaoVoltarLabel = 'VOLTAR';

export const botaoComprar = (isHorizontal, clickHandler, botaoComprarDisable = false) => (
  <Button
    label={botaoComprarLabel}
    width={isHorizontal ? calculaTamanho(3.652) : '50%'}
    height={isHorizontal ? '100%' : '75%'}
    clickHandler={clickHandler}
    disabled={botaoComprarDisable}
    className="button"
  />
);

export const botaoVoltar = (isHorizontal, clickHandler, botaoComprarDisable = false) => (
  <Button
    label={botaoVoltarLabel}
    width={isHorizontal ? calculaTamanho(3.652) : '50%'}
    height={isHorizontal ? '100%' : '75%'}
    clickHandler={clickHandler}
    className="button"
    disabled={botaoComprarDisable}
    voltar
  />
);
