/* eslint-disable no-console */
import { unMask } from 'remask';
import toast from 'react-hot-toast';
import { calculoTotalColeira, getFretevalue } from './calculosGerais';
import { apiUrl, apiFetch } from './apiHelper';

// old browsers
import 'whatwg-fetch';
import * as apiHelp from './apiHelper';

const enviarApi = async (data, orderId) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        order_id: orderId,
        token: data.token,
      },
    ),
  };

  const url = `${apiUrl()}/vitrine/payment`;

  const successFunctionApi = (result) => {
    apiHelp.verificaSucesso(result);
  };

  await apiFetch(url, requestOptions, 1, successFunctionApi);
};

const enviarFormularioApi = async (form, retirarLoja, count) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        cpf: unMask(form.cpf),
        email: form.email,
        name: form.nome,
        phone: unMask(form.celular),
        cep: unMask(form.cep),
        shop_id: retirarLoja ? apiHelp.getLojaId(form) : null,
        shipping_value: getFretevalue(form, retirarLoja),
        product_price: calculoTotalColeira(count), // (preço total dos produtos sem frete)
        pages: '1', // pra coleiras vai ser sempre "1"
        quantity: count,
      },
    ),
  };

  try {
    const result = await fetch(`${apiUrl()}/vitrine/order`, requestOptions);
    return result ? await result.json() : result;
  } catch {
    toast('Erro ao acessar api');
    return null;
  }
};

const enviarApiBoleto = async (data) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const url = `${apiUrl()}/vitrine/boleto?token=${data.token}`;
    const result = await (await fetch(url, requestOptions)).json();
    console.log('boleto', result);

    if (result?.errorcode === 100) {
      toast(result?.errormsg);
    }

    return result;
  } catch (err) {
    console.log('Erro ao buscar boleto ', err);
    return null;
  }
};

const trataSucessoApi = async (data, setBoletoUrl, setTextoCarregando, count) => {
  if (data.payment_method === 'boleto') {
    setTextoCarregando('Gerando seu boleto...');
    const result = await enviarApiBoleto(data, 1);
    if (result?.boleto_url?.length > 0) {
      setTextoCarregando(null);
      setBoletoUrl(result.boleto_url);
    } else {
      setTimeout(
        async () => trataSucessoApi(data, setBoletoUrl, setTextoCarregando, count + 1),
        1000 * count,
      );
    }
  } else setTextoCarregando(null);
};

export const envioPagarme = async (
  form,
  retirarLoja,
  count,
  setFormulary,
  setTelaAtual,
  setBoletoUrl,
  setTextoCarregando,
  botaoComprarDisable,
  setBotaoComprarDisable,
) => {
  const [formularioValido, mensagem] = apiHelp.formularioCompleto(form, retirarLoja, setFormulary);

  if (formularioValido) {
    const result = await enviarFormularioApi(form, retirarLoja, count);

    if (result) {
      if (!apiHelp.verificaSucesso(result)) {
        toast(result.errormsg);
        console.log('errorr', result);
        apiHelp.trataErro(result.errorfield, setFormulary);
      } else if (result.order_id && result.postback_url && result.encryption_key) {
        const celular = unMask(form.celular);
        apiHelp.resetaErros(form, setFormulary, botaoComprarDisable, setBotaoComprarDisable);

        try {
          const checkout = new window.PagarMeCheckout.Checkout({
            encryption_key: result.encryption_key,
            success: async (data) => {
              setBotaoComprarDisable(true);
              setTextoCarregando('Processando pedido...');
              setTelaAtual('pedidoFinalizado');
              await enviarApi(data, result.order_id);
              await trataSucessoApi(data, setBoletoUrl, setTextoCarregando, 1);
            },
            catch(err) {
              toast('Erro ao efetuar pagamento');
              console.log('Erro pagarme', err);
            },
            close() {
              console.log('Fechou a modal do pagarme.');
            },
          });

          checkout?.open({
            amount: apiHelp.calculoAmountColeira(form, retirarLoja, count),
            postbackUrl: result.postback_url,
            customerData: 'false',
            createToken: 'true',
            customerName: form.nome,
            customerDocumentNumber: form.cpf,
            customerEmail: form.email,
            customerPhoneNumber: Number.parseInt(celular.substring(2), 10),
            customerPhoneDdd: celular.substring(0, 2),
            customerAddressStreet: form.rua,
            customerAddressStreetNumber: form.numero,
            customerAddressComplementary: form.complemento,
            customerAddressNeighborhood: form.bairro,
            customerAddressCity: form.cidade,
            customerAddressState: form.estado,
            customerAddressZipcode: form.cep,
            uiColor: '#FF9807',
          });
        } catch (e) {
          console.log('Erro checkout', e);
        }
      } else {
        toast('Erro resposta api');
        console.log('Resposta incompleta da api');
      }
    }
  } else {
    toast(mensagem);
  }
};
